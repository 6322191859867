import React, { Fragment, useEffect, useState } from 'react';
import {
  DragDropImage,
  StepperWrapper,
  TextFieldWrapper,
} from '../../components';
import {
  CircularProgress,
  Grid,
  LinearProgress,
  MenuItem,
} from '@mui/material';
import { GppBadOutlined, TaskAltOutlined } from '@mui/icons-material';
import { Formik } from 'formik';
import * as yup from 'yup';
import { validationSchema } from '../../constants/validationSchema';
import LocationService from '../../services/location.service';
import CompanyService from '../../services/company.service';
import useScreen from '../../hooks/useScreen';
import { toAbsoluteURL } from '../../constants/shared-methods';
import { useSelector } from 'react-redux';
import UserService from '../../services/user.service';
import EmployeeService from '../../services/employee.service';
import { useNavigate } from 'react-router-dom';

function Component() {
  const steps = ['Media', 'Information', 'Summary'];
  const { isPageLoaded } = useScreen();
  const { user, company, isCompany, isEmployee, isUser, employee } =
    useSelector(({ user }) => user);
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(0);
  const [countryList, setCountryList] = useState([]);
  const [cityList, setCityList] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isCompanyLoading, setIsCompanyLoading] = useState(true);

  const [lightLogoFile, setLightLogoFile] = useState(null);
  const [lightLogoUrl, setLightLogoUrl] = useState(null);
  const [darkLogoFile, setDarkLogoFile] = useState(null);
  const [darkLogoUrl, setDarkLogoUrl] = useState(null);
  const [profileFile, setProfileFile] = useState(null);
  const [profileUrl, setProfileUrl] = useState(null);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [cityID, setCityID] = useState('');
  const [countryID, setCountryID] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [slogan, setSlogan] = useState('');
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [designation, setDesignation] = useState('');
  const [selfDescription, setSelfDescription] = useState('');

  const [responseData, setResponseData] = useState(null);

  const geCountries = () => {
    LocationService.getCountries().then((res) => {
      setCountryList(res.data);
    });
  };

  const getCitiesByCountry = (value) => {
    if (value) {
      LocationService.getCitiesByCountry(value).then((res) => {
        setCityList(res.data);
      });
    } else setCityList(null);
  };

  const getUserData = () => {
    if (user?.country_id) {
      getCitiesByCountry(user?.country_id);
    }
    setName(user?.name);
    setEmail(user?.email);
    setPhone(user?.phone);
    setCityID(user?.city_id);
    setCountryID(user?.country_id);
    setStreetAddress(user.street_address);
    if (isEmployee) {
      setDesignation(employee?.designation);
      setSelfDescription(employee?.self_description);
    }
    if (isCompany) {
      setWebsiteUrl(company?.website_url);
      setSlogan(company?.slogan);
      setLightLogoUrl(toAbsoluteURL(user?.photo));
      setDarkLogoUrl(toAbsoluteURL(company?.dark_logo));
    } else {
      setProfileUrl(toAbsoluteURL(user?.photo));
    }
    setIsCompanyLoading(false);
  };

  useEffect(() => {
    if (isPageLoaded) {
      getUserData();
      geCountries();
    }
  }, [isPageLoaded]);

  const updateProfile = () => {
    setIsLoading(true);
    if (isEmployee) {
      const formObj = {
        employeeGeneral: {
          name: name,
          phone: phone,
          city_id: cityID,
          country_id: countryID,
          street_address: streetAddress,
          status: 1,
        },
        employeeSpecifics: {
          self_description: selfDescription,
          designation: designation,
        },
      };

      let formType;
      if (profileFile) {
        formType = 'form-data';
        formObj.employeeGeneral.photo = profileFile;
      }

      EmployeeService.updateEmployeeDetails(employee.id, formObj, formType)
        .then((res) => {
          if (res.success) {
            setResponseData({
              status: true,
              message: 'Profile has been updated successfully',
            });
            window.location.reload();

            setTimeout(() => navigate(-1), 1000);
          } else {
            setResponseData({ status: false, message: 'Something went wrong' });
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setResponseData({
            status: false,
            message: error?.response?.data?.message || 'Something went wrong',
          });
          setIsLoading(false);
        });
    } else if (isCompany) {
      const formObj = {
        companyGeneral: {
          name: name,
          email: email,
          phone: phone,
          role_id: 3,
          city_id: cityID,
          country_id: countryID,
          street_address: streetAddress,
          status: 1,
        },
        companySpecifics: {
          slogan: slogan,
          website_url: websiteUrl,
        },
      };

      if (lightLogoFile !== null) {
        formObj.companyGeneral['photo'] = lightLogoFile;
      }

      if (darkLogoFile !== null) {
        formObj.companySpecifics['dark_logo'] = darkLogoFile;
      }

      CompanyService.updateCompany(company.id, formObj)
        .then((res) => {
          if (res.success) {
            setResponseData({
              status: true,
              message: 'Profile has been updated successfully',
            });
            window.location.reload();

            setTimeout(() => navigate(-1), 1000);
          } else {
            setResponseData({ status: false, message: 'Something went wrong' });
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setResponseData({
            status: false,
            message: error?.response?.data?.message || 'Something went wrong',
          });
          setIsLoading(false);
        });
    } else {
      var formObj = {
        name: name,
        email: email,
        phone: phone,
        street_address: streetAddress,
        country_id: countryID || 0,
        city_id: cityID || 0,
      };

      if (profileFile !== null) {
        formObj.photo = profileFile;
      }

      UserService.updateProfile(user.id, formObj, 'form-data')
        .then((res) => {
          if (res.success) {
            setResponseData({
              status: true,
              message: 'Profile has been updated successfully',
            });
            window.location.reload();

            setTimeout(() => navigate(-1), 1000);
          } else {
            setResponseData({ status: false, message: 'Something went wrong' });
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setResponseData({
            status: false,
            message: error?.response?.data?.message || 'Something went wrong',
          });
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (activeTab === 2) {
      updateProfile();
    }
  }, [activeTab]);

  return (
    <div>
      <div className="text-2xl">Profile Alteration</div>

      <div className="mt-12 mx-auto">
        <div className="overflow-x-auto mx-auto max-w-[800px]">
          <StepperWrapper activeStep={activeTab} steps={steps} />
        </div>

        {!isCompanyLoading ? (
          <div>
            {activeTab === 0 && (
              <div className="mt-12 mx-auto max-w-[800px]">
                <Formik
                  initialValues={{
                    lightLogoFile: lightLogoFile,
                    lightLogoUrl: lightLogoUrl,
                    darkLogoFile: darkLogoFile,
                    darkLogoUrl: darkLogoUrl,
                    profileFile: profileFile,
                    profileUrl: profileUrl,
                  }}
                  validationSchema={yup.object().shape({
                    lightLogoFile: validationSchema.image,
                    darkLogoFile: validationSchema.image,
                    profileFile: validationSchema.image,
                  })}
                  onSubmit={(values, actions) => {
                    try {
                      setDarkLogoFile(values.darkLogoFile);
                      setDarkLogoUrl(values.darkLogoUrl);
                      setLightLogoFile(values.lightLogoFile);
                      setLightLogoUrl(values.lightLogoUrl);
                      setProfileFile(values.profileFile);
                      setProfileUrl(values.profileUrl);
                      setActiveTab(1);
                    } catch (error) {}
                  }}
                >
                  {({
                    values,
                    errors,
                    setFieldValue,
                    handleSubmit,
                    handleBlur,
                  }) => (
                    <div>
                      <Grid container spacing={4}>
                        {isCompany ? (
                          <Fragment>
                            <Grid item md={6} xs={12}>
                              <DragDropImage
                                error={errors.lightLogoFile}
                                onBlur={handleBlur}
                                name="lightLogoFile"
                                onChangeImage={(file) => {
                                  if (file) {
                                    setFieldValue('lightLogoFile', file);
                                    setFieldValue(
                                      'lightLogoUrl',
                                      URL.createObjectURL(file)
                                    );
                                  }
                                }}
                                preview={values.lightLogoUrl}
                                title="Light Version"
                              />
                            </Grid>

                            <Grid item md={6} xs={12}>
                              <DragDropImage
                                error={errors.darkLogoFile}
                                onBlur={handleBlur}
                                name="darkLogoFile"
                                onChangeImage={(file) => {
                                  if (file) {
                                    setFieldValue('darkLogoFile', file);
                                    setFieldValue(
                                      'darkLogoUrl',
                                      URL.createObjectURL(file)
                                    );
                                  }
                                }}
                                preview={values.darkLogoUrl}
                                title="Dark Version"
                              />
                            </Grid>
                          </Fragment>
                        ) : (
                          <Grid item md={6} xs={12}>
                            <DragDropImage
                              error={errors.profileFile}
                              onBlur={handleBlur}
                              name="profileFile"
                              onChangeImage={(file) => {
                                if (file) {
                                  setFieldValue('profileFile', file);
                                  setFieldValue(
                                    'profileUrl',
                                    URL.createObjectURL(file)
                                  );
                                }
                              }}
                              preview={values.profileUrl}
                              title="Profile picture"
                              cropable={true}
                            />
                          </Grid>
                        )}
                      </Grid>
                      <div className="flex items-center justify-end mt-8">
                        <button
                          type="button"
                          onClick={handleSubmit}
                          className="text-xs bg-main text-center px-4 py-1 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  )}
                </Formik>
              </div>
            )}

            {activeTab === 1 && (
              <div className="mt-12 mx-auto max-w-[800px]">
                <Formik
                  initialValues={{
                    name: name,
                    slogon: slogan,
                    phone: phone,
                    email: email,
                    website: websiteUrl,
                    address: streetAddress,
                    city: cityID,
                    country: countryID,
                    selfDescription: selfDescription,
                    designation: designation,
                  }}
                  validationSchema={yup.object().shape({
                    name: validationSchema.name,
                    slogon: isCompany
                      ? validationSchema.required('Company Slogon')
                      : validationSchema.nullable,
                    email: validationSchema.email,
                    designation: isEmployee
                      ? validationSchema.required('Designation')
                      : validationSchema.nullable,
                  })}
                  onSubmit={(values, actions) => {
                    try {
                      setName(values.name);
                      setSlogan(values.slogon);
                      setPhone(values.phone);
                      setEmail(values.email);
                      setWebsiteUrl(values.website);
                      setStreetAddress(values.address);
                      setCityID(values.city);
                      setCountryID(values.country);
                      setDesignation(values.designation);
                      setSelfDescription(values.selfDescription);
                      setActiveTab(2);
                    } catch (error) {}
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => {
                    const goPreviousTab = () => {
                      setName(values.name);
                      setSlogan(values.slogon);
                      setPhone(values.phone);
                      setEmail(values.email);
                      setWebsiteUrl(values.website);
                      setStreetAddress(values.address);
                      setCityID(values.city);
                      setCountryID(values.country);
                      setDesignation(values.designation);
                      setSelfDescription(values.selfDescription);
                      setActiveTab(0);
                    };
                    return (
                      <div>
                        <Grid container spacing={4}>
                          <Grid item md={4} sm={6} xs={12}>
                            <div className="flex flex-col gap-2">
                              <label className="text-xs">
                                {isCompany ? 'Company Title' : 'Name'}{' '}
                                <span className="text-red-500">*</span>
                              </label>
                              <TextFieldWrapper
                                placeholder="Placeholder"
                                name="name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                                helperText={touched.name && errors.name}
                                error={!!touched.name && !!errors.name}
                              />
                            </div>
                          </Grid>

                          {isCompany && (
                            <Grid item md={4} sm={6} xs={12}>
                              <div className="flex flex-col gap-2">
                                <label className="text-xs">
                                  Company Slogan{' '}
                                  <span className="text-red-500">*</span>
                                </label>
                                <TextFieldWrapper
                                  placeholder="Placeholder"
                                  name="slogon"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.slogon}
                                  helperText={touched.slogon && errors.slogon}
                                  error={!!touched.slogon && !!errors.slogon}
                                />
                              </div>
                            </Grid>
                          )}

                          {isEmployee && (
                            <Grid item md={4} sm={6} xs={12}>
                              <div className="flex flex-col gap-2">
                                <label className="text-xs">
                                  Designation (Optional)
                                </label>
                                <TextFieldWrapper
                                  placeholder="Placeholder"
                                  name="designation"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.designation}
                                  helperText={
                                    touched.designation && errors.designation
                                  }
                                  error={
                                    !!touched.designation &&
                                    !!errors.designation
                                  }
                                />
                              </div>
                            </Grid>
                          )}

                          <Grid item md={4} sm={6} xs={12}>
                            <div className="flex flex-col gap-2">
                              <label className="text-xs">
                                Phone (Optional)
                              </label>
                              <TextFieldWrapper
                                placeholder="Placeholder"
                                name="phone"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.phone}
                                helperText={touched.phone && errors.phone}
                                error={!!touched.phone && !!errors.phone}
                              />
                            </div>
                          </Grid>

                          <Grid item md={4} sm={6} xs={12}>
                            <div className="flex flex-col gap-2">
                              <label className="text-xs">
                                Email
                                <span className="text-red-500">*</span>
                              </label>
                              <TextFieldWrapper
                                placeholder="Placeholder"
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                helperText={touched.email && errors.email}
                                error={!!touched.email && !!errors.email}
                                disabled
                              />
                            </div>
                          </Grid>

                          {isCompany && (
                            <Grid item md={4} sm={6} xs={12}>
                              <div className="flex flex-col gap-2">
                                <label className="text-xs">
                                  Company Website
                                </label>
                                <TextFieldWrapper
                                  placeholder="Placeholder"
                                  name="website"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.website}
                                  helperText={touched.website && errors.website}
                                  error={!!touched.website && !!errors.website}
                                />
                              </div>
                            </Grid>
                          )}

                          <Grid item md={4} sm={6} xs={12}>
                            <div className="flex flex-col gap-2">
                              <label className="text-xs">
                                Street Address (Optional)
                              </label>
                              <TextFieldWrapper
                                placeholder="Placeholder"
                                name="address"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.address}
                                helperText={touched.address && errors.address}
                                error={!!touched.address && !!errors.address}
                              />
                            </div>
                          </Grid>

                          <Grid item md={4} sm={6} xs={12}>
                            <div className="flex flex-col gap-2">
                              <label className="text-xs">
                                Country (Optional)
                              </label>
                              <TextFieldWrapper
                                select
                                placeholder="Placeholder"
                                name="country"
                                onChange={(e) => {
                                  handleChange(e);
                                  setFieldValue('city', '');
                                  getCitiesByCountry(e.target.value);
                                }}
                                onBlur={handleBlur}
                                value={values.country}
                                helperText={touched.country && errors.country}
                                error={!!touched.country && !!errors.country}
                              >
                                <MenuItem value={''}>Select</MenuItem>
                                {countryList &&
                                  Array.isArray(countryList) &&
                                  countryList.map((item, index) => (
                                    <MenuItem key={index} value={item.value}>
                                      {item.label}
                                    </MenuItem>
                                  ))}
                              </TextFieldWrapper>
                            </div>
                          </Grid>

                          <Grid item md={4} sm={6} xs={12}>
                            <div className="flex flex-col gap-2">
                              <label className="text-xs">City (Optional)</label>
                              <TextFieldWrapper
                                select
                                disabled={!!!cityList}
                                placeholder="Placeholder"
                                name="city"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.city}
                                helperText={touched.city && errors.city}
                                error={!!touched.city && !!errors.city}
                              >
                                <MenuItem value={''}>Select</MenuItem>
                                {cityList &&
                                  Array.isArray(cityList) &&
                                  cityList.map((item, index) => (
                                    <MenuItem key={index} value={item.value}>
                                      {item.label}
                                    </MenuItem>
                                  ))}
                              </TextFieldWrapper>
                            </div>
                          </Grid>

                          {isEmployee && (
                            <Grid item xs={12}>
                              <div className="flex flex-col gap-2">
                                <label className="text-xs">
                                  SELF SHORT DESCRIPTION (OPTIONAL)
                                </label>
                                <TextFieldWrapper
                                  multiline
                                  minRows={3}
                                  placeholder="Placeholder"
                                  name="selfDescription"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.selfDescription}
                                  helperText={
                                    touched.selfDescription &&
                                    errors.selfDescription
                                  }
                                  error={
                                    !!touched.selfDescription &&
                                    !!errors.selfDescription
                                  }
                                />
                              </div>
                            </Grid>
                          )}
                        </Grid>

                        <div className="flex items-center justify-between mt-8">
                          <button
                            onClick={goPreviousTab}
                            className="text-xs border border-1 border-[var(--blue)] text-center px-4 py-1 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
                          >
                            Back
                          </button>

                          <button
                            type="button"
                            onClick={handleSubmit}
                            className="text-xs bg-main text-center px-4 py-1 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    );
                  }}
                </Formik>
              </div>
            )}

            {activeTab === 2 && (
              <div className="mt-12 mx-auto max-w-[800px]">
                <div className="min-h-96 flex items-center justify-center flex-col gap-5">
                  {isLoading ? (
                    <CircularProgress />
                  ) : responseData?.status ? (
                    <Fragment>
                      <TaskAltOutlined
                        className="text-[var(--yellow)]"
                        sx={{ fontSize: '5rem' }}
                      />
                      <div className="text-[var(--yellow)] font-bold text-2xl text-center">
                        {responseData?.message}
                      </div>
                    </Fragment>
                  ) : (
                    !responseData?.status &&
                    responseData?.message && (
                      <Fragment>
                        <GppBadOutlined
                          className="text-[var(--pink)]"
                          sx={{ fontSize: '5rem' }}
                        />
                        <div className="text-[var(--pink)] font-bold text-2xl text-center">
                          {responseData?.message}
                        </div>
                      </Fragment>
                    )
                  )}
                </div>

                {!isLoading && !responseData?.status && (
                  <div className="flex items-center justify-between mt-8">
                    <button
                      onClick={() => {
                        setResponseData(null);
                        setActiveTab(1);
                      }}
                      className="text-xs border border-1 border-[var(--blue)] text-center px-4 py-1 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
                    >
                      Back
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <div className="mt-36 mx-auto max-w-[500px]">
            <LinearProgress />
          </div>
        )}
      </div>
    </div>
  );
}

export default Component;
